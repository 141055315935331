import React, { useState } from 'react';
import { Button, Col, Input, Row, Checkbox } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { AiFillLinkedin, AiOutlineInstagram, AiOutlineMail } from "react-icons/ai";
import { BsFillTelephoneFill, BsFillEnvelopeFill, BsTelephoneFill } from 'react-icons/bs'
import { FaFacebookF } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import { getInTouch } from "../../redux/actions/authActions"
import { useDispatch } from 'react-redux';
import usaFlag from '../../assets/usaFlag.png'
import pakFlag from '../../assets/pakFlag.webp'
import AusFlag from '../../assets/AusFlag.webp'

function Form() {
  const dispatch = useDispatch();
  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [subject, setSubject] = useState(null)
  const [message, setMessage] = useState(null)

  const submitHandler = () => {
    if (name, email, subject, message) {
      const payload = {
        name: name,
        email: email,
        subject: subject,
        message: message,
      }
      dispatch(getInTouch(payload))
    }
  }

  return (
    <div className="parent_container waveImageStyle">
      <div className="appDevelopment_container" style={{ marginTop: "100px" }}>
        <Row gutter={30}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <div className="FormText">
              <h1 className="heading">
                What Set Us <span className="textStyle">Apart</span>
              </h1>
              <p>
                Decotechs team consists of highly knowledgeable and
                well-experienced developers. Our Expert developers are always
                ready to provide high-quality services on time and within
                budget. Our developers never disappoint our clients, and our
                main aim is to make our clients highly satisfied with our
                services. If you have any questions regarding your project, feel
                free to ask. How can we help you?
              </p>
              <div className="address">
                {/* <div>
                  <h1>Address</h1>
                  <p>Amber Estate, Extension Office 501, <br />
                    Bangalore Town Block A, <br />
                    Karachi, Pakistan
                  </p>
                  <br />
                </div> */}
                <div>
                  <h1>Working Hours</h1>
                  {/* <p>Monday to Friday: 9AM – 8PM</p> */}
                  <p>Unlocking Possibilities Around the Clock: Your 24/7 Software Partner</p>
                  {/* <p>Week-End: 10AM – 5PM</p> */}
                </div>
              </div>
              <div className="contact" style={{ color: "var(--textColor)" }}>
                <div>
                  <h1>Contact</h1>
                  <div className='pakInfo' >
                    <div style={{display: 'flex' ,alignItems: 'center'}}>
                      <img style={{width: '50px' ,height: 'auto', margin: '.5rem'}} src={pakFlag}></img>
                      <h2 style={{ color: "var(--textColor)" }}>Pakistan</h2>
                    </div>
                    <div className='location' style={{ display: 'flex' }}>
                      <div className='icon'><HiLocationMarker className='icon' /></div>
                      <p>Office 203 2nd Floor, Amber Estate, Bangalore Town <br /> Block A Bangalore Town, Karachi, Karachi City, Sindh 75350, Pakistan</p>
                    </div>
                    <div className='phone' style={{ display: 'flex' }}>
                      <div>
                        <BsTelephoneFill />
                      </div>
                      <p><a href="tel:+923412636264">+92 341 2636264</a></p>
                    </div>
                    <div className='email' style={{ display: 'flex' }}>
                      <div>
                        <AiOutlineMail />
                      </div>
                      <p><a href='mailto:info@decotechs.com'>info@decotechs.com</a></p>
                    </div>

                  </div>
                  <div className='pakInfo'>
                    <div style={{display: 'flex' ,alignItems: 'center'}}>
                      <img style={{width: '50px' ,height: 'auto', margin: '.5rem'}} src={usaFlag}></img>
                      <h2 style={{ color: "var(--textColor)" }}>USA</h2>
                    </div>
                    <div className='location' style={{ display: 'flex' }}>
                      <div><HiLocationMarker /></div>
                      <p>Office # 1120  11303 wilcrest drive south Houston Tx 77099, USA</p>
                    </div>
                    <div className='phone' style={{ display: 'flex' }}>
                      <div>
                        <BsTelephoneFill />
                      </div>
                      <p><a href="tel:+13462100048">+1 (346) 210-0048</a></p>
                    </div>
                    <div className='email' style={{ display: 'flex' }}>
                      <div>
                        <AiOutlineMail />
                      </div>
                      <p><a href='mailto:info@decotechs.com'>info@decotechs.com</a></p>
                    </div>

                  </div>
                  <div className='pakInfo'>
                    <div style={{display: 'flex' ,alignItems: 'center'}}>
                      <img style={{width: '50px' ,height: 'auto', margin: '.5rem'}} src={AusFlag}></img>
                      <h2 style={{ color: "var(--textColor)" }}>Australia</h2>
                    </div>
                    <div className='location' style={{ display: 'flex' }}>
                      <div><HiLocationMarker /></div>
                      <p>Fairhaven Boulevard, Melton West, Victoria 3337</p>
                    </div>
                    <div className='phone' style={{ display: 'flex' }}>
                      <div>
                        <BsTelephoneFill />
                      </div>
                      <p><a href="tel:+61426117866">+61 426 117 866</a></p>
                    </div>
                    <div className='email' style={{ display: 'flex' }}>
                      <div>
                        <AiOutlineMail />
                      </div>
                      <p><a href='mailto:info@decotechs.com'>info@decotechs.com</a></p>
                    </div>

                  </div>

                </div>
                {/* <p><BsFillEnvelopeFill /> info@decotechs.com</p> */}
                {/* <p><BsFillTelephoneFill /> 0341 2636264</p> */}

                <div className="icon">
                  <h1>Socials</h1>
                  <div>
                    <a href="https://www.facebook.com/Decotechsdotcom">
                      <FaFacebookF size={20} />
                    </a>
                    <a href="https://www.linkedin.com/company/decotechs">
                      <AiFillLinkedin size={20} />
                    </a>
                    <a href="https://www.instagram.com/decotechs/">
                      <AiOutlineInstagram size={20} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <div className="form_container">
              <h1 className="heading">Get In Touch</h1>
              <div>
                <Input type="text" placeholder="Your Name" onChange={(e) => setName(e.target.value)} />
                <Input type="text" placeholder="Your Email" onChange={(e) => setEmail(e.target.value)} />
              </div>
              <Input type="text" placeholder="Subject" onChange={(e) => setSubject(e.target.value)} />
              <Input type='number' placeholder='Your Contact Number' maxLength={20}/>
              <Checkbox>By checking this box, I agree to receive texts from Decotechs & Company at provided mobile number.</Checkbox>
              <TextArea className="textArea" placeholder="Message" rows={10} onChange={(e) => setMessage(e.target.value)} />
              <Button shape="round" size="large" onClick={submitHandler}>
                SEND
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Form;
